const ProductList = {
            "1":{
                "type": "3D",
                "imageURL":"https://api.getcandid.com/image/h/a%2fstream-media%2fb49f10b4-08ac-4b73-843b-337f76950021_17872155848075384_standard.jpg?w=400",
                "threekit" : "d2836037-a2b8-46ce-be7e-7aeb246f5066",
                "name" :"Door Designer",
                "scene": "",
                "brand": "Build your door"
            }
        };
export {ProductList};