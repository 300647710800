import React from "react";
import { Layout, Menu, Divider } from "antd";
import Products from "./components/ProductList";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Product from "./pages/Product";

const { Header, Content, Footer } = Layout;
function App(props) {
  return (
    <div className="App">
      <Layout className="layout">
        <center>
          <Header id="header">
        
          </Header>
        </center>
        <Content style={{ padding: "1em" }}>
          <Router>
            <Switch>
              <Route path="/demo/:productId" component={Product}></Route>
              <Route path="/" component={Products}></Route>
            </Switch>
          </Router>
        </Content>
      </Layout>

      <div style={{ textAlign: "center" }}>Threekit ©2021</div>
    </div>
  );
}

export default App;
