const attrs = [
  {
    type: "String",
    name: "Door Configuration",
    id: "fc9c0654-4bed-425e-87e1-3bf1aef880e0",
    blacklist: [],
    //assetType: "material",
    values: [
      "Single Door with Sidelites",
      "Single Door",
      "Single Door with Right Sidelite",
      "Single Door with Left Sidelite",
      "Double Doors",
      "Double Doors with Sidelites",
    ],
    //defaultValue: { "Single Door with Sidelites" },
  },
  {
    type: "Asset",
    name: "Door Finish",
    id: "1d43c1ab-ec86-4b30-b021-79e0774901d1",
    blacklist: [],
    values: [
      {
        assetId: "4de5b271-72cf-4744-900d-5ca3ea215d79",
        name: "Wildflower Honey",
      },
      { assetId: "36321483-ae86-41ed-a435-73f0b44ec854", name: "Mulberry" },
      { assetId: "d1eae2ba-6736-4b90-b25e-3c22d8dd68bc", name: "Rustic Clay" },
      { assetId: "e4ab1c50-5b52-4fd9-a837-c27b76d1ac1c", name: "Redwood" },
      { assetId: "43e3202d-ebd6-45d8-a3e1-811f1d56326a", name: "New Earth" },
      { assetId: "182eda6a-b763-46f7-a8c3-12e7566633e9", name: "Driftwood" },
      { assetId: "dd0907cb-417c-4f78-b047-3e763e4f1bc8", name: "Chestnut" },
      {
        assetId: "e34b31b1-744b-4b13-9b47-39cae6048bd3",
        name: "Autumn Harvest",
      },
      { assetId: "211c6fbe-c346-477e-bb0b-2cc25c5b0247", name: "Barley" },
      { assetId: "43b07b97-de38-4728-9542-d0f70ad7591c", name: "Cabernet" },
      { assetId: "eb5e1487-fa22-411e-b4ae-17a52a72a1b4", name: "Cypress" },
      { assetId: "74e058ff-7ded-41c0-b40e-8aad82c46c41", name: "Indigo" },
      { assetId: "379720d4-c86b-4c6e-8401-376bc892e3a1", name: "Granite" },
      { assetId: "25addead-3c5a-49ee-af6d-1ad3bd64968c", name: "Apline" },
      { assetId: "41d94014-4776-4f92-8498-a6cfbd30e37e", name: "Ruby Red" },
    ],
    //defaultValue: { assetId: "4de5b271-72cf-4744-900d-5ca3ea215d79",
  },
  {
    type: "Asset",
    name: "Door Style",
    id: "43235fee-aa22-4953-8c39-c7e99d92372c",
    blacklist: [],
    assetType: "model",
    values: [
      { assetId: "56bba86d-0674-4ffd-a2d9-8711ef11f3f0", name: "Door 1" },
      { assetId: "4de4072d-c569-4433-bd6d-582f48d1ecb8", name: "Door 2" },
      { assetId: "df58c805-a512-4dcb-a9fb-e3fe9c7acd0f", name: "Door 3" },
    ],
    defaultValue: { assetId: "" },
  },
  {
    type: "String",
    name: "Glass Style",
    id: "bb795626-5773-44e7-969e-615aa26ebfe6",
    blacklist: [],
    //assetType: "material",
    values: ["Blackstone", "Texas Star"],
    //defaultValue: { "Single Door with Sidelites" },
  },
  {
    type: "Asset",
    name: "Frame Finish",
    id: "40c3ddbd-d62c-4c4f-b180-27fe962211cd",
    blacklist: [],
    values: [
      {
        assetId: "4de5b271-72cf-4744-900d-5ca3ea215d79",
        name: "Wildflower Honey",
      },
      { assetId: "36321483-ae86-41ed-a435-73f0b44ec854", name: "Mulberry" },
      { assetId: "d1eae2ba-6736-4b90-b25e-3c22d8dd68bc", name: "Rustic Clay" },
      { assetId: "e4ab1c50-5b52-4fd9-a837-c27b76d1ac1c", name: "Redwood" },
      { assetId: "43e3202d-ebd6-45d8-a3e1-811f1d56326a", name: "New Earth" },
      { assetId: "182eda6a-b763-46f7-a8c3-12e7566633e9", name: "Driftwood" },
      { assetId: "dd0907cb-417c-4f78-b047-3e763e4f1bc8", name: "Chestnut" },
      {
        assetId: "e34b31b1-744b-4b13-9b47-39cae6048bd3",
        name: "Autumn Harvest",
      },
      { assetId: "211c6fbe-c346-477e-bb0b-2cc25c5b0247", name: "Barley" },
      { assetId: "43b07b97-de38-4728-9542-d0f70ad7591c", name: "Cabernet" },
      { assetId: "eb5e1487-fa22-411e-b4ae-17a52a72a1b4", name: "Cypress" },
      { assetId: "74e058ff-7ded-41c0-b40e-8aad82c46c41", name: "Indigo" },
      { assetId: "379720d4-c86b-4c6e-8401-376bc892e3a1", name: "Granite" },
      { assetId: "25addead-3c5a-49ee-af6d-1ad3bd64968c", name: "Apline" },
      { assetId: "41d94014-4776-4f92-8498-a6cfbd30e37e", name: "Ruby Red" },
    ],
    //defaultValue: { assetId: "4de5b271-72cf-4744-900d-5ca3ea215d79",
  },
  {
    type: "Asset",
    name: "Sidelite Finish",
    id: "0ebf3d55-ffe7-4b7f-91b7-02f4c9d6e6c0",
    blacklist: [],
    values: [
      {
        assetId: "4de5b271-72cf-4744-900d-5ca3ea215d79",
        name: "Wildflower Honey",
      },
      { assetId: "36321483-ae86-41ed-a435-73f0b44ec854", name: "Mulberry" },
      { assetId: "d1eae2ba-6736-4b90-b25e-3c22d8dd68bc", name: "Rustic Clay" },
      { assetId: "e4ab1c50-5b52-4fd9-a837-c27b76d1ac1c", name: "Redwood" },
      { assetId: "43e3202d-ebd6-45d8-a3e1-811f1d56326a", name: "New Earth" },
      { assetId: "182eda6a-b763-46f7-a8c3-12e7566633e9", name: "Driftwood" },
      { assetId: "dd0907cb-417c-4f78-b047-3e763e4f1bc8", name: "Chestnut" },
      {
        assetId: "e34b31b1-744b-4b13-9b47-39cae6048bd3",
        name: "Autumn Harvest",
      },
      { assetId: "211c6fbe-c346-477e-bb0b-2cc25c5b0247", name: "Barley" },
      { assetId: "43b07b97-de38-4728-9542-d0f70ad7591c", name: "Cabernet" },
      { assetId: "eb5e1487-fa22-411e-b4ae-17a52a72a1b4", name: "Cypress" },
      { assetId: "74e058ff-7ded-41c0-b40e-8aad82c46c41", name: "Indigo" },
      { assetId: "379720d4-c86b-4c6e-8401-376bc892e3a1", name: "Granite" },
      { assetId: "25addead-3c5a-49ee-af6d-1ad3bd64968c", name: "Apline" },
      { assetId: "41d94014-4776-4f92-8498-a6cfbd30e37e", name: "Ruby Red" },
    ],
    //defaultValue: { assetId: "4de5b271-72cf-4744-900d-5ca3ea215d79",
  },
  {
    type: "String",
    name: "Surround",
    id: "04ccf871-f84a-4a1b-852c-da6f14dde058",
    blacklist: [],
    //assetType: "material",
    values: ["None", "TDS-CCCFLT", "TDS-TRDPLN"],
    //defaultValue: { "Single Door with Sidelites" },
  },
  {
    type: "Asset",
    name: "Surround Finish",
    id: "abf3208a-0360-4e47-b4b4-662460dd8913",
    blacklist: [],
    values: [
      {
        assetId: "4de5b271-72cf-4744-900d-5ca3ea215d79",
        name: "Wildflower Honey",
      },
      { assetId: "36321483-ae86-41ed-a435-73f0b44ec854", name: "Mulberry" },
      { assetId: "d1eae2ba-6736-4b90-b25e-3c22d8dd68bc", name: "Rustic Clay" },
      { assetId: "e4ab1c50-5b52-4fd9-a837-c27b76d1ac1c", name: "Redwood" },
      { assetId: "43e3202d-ebd6-45d8-a3e1-811f1d56326a", name: "New Earth" },
      { assetId: "182eda6a-b763-46f7-a8c3-12e7566633e9", name: "Driftwood" },
      { assetId: "dd0907cb-417c-4f78-b047-3e763e4f1bc8", name: "Chestnut" },
      {
        assetId: "e34b31b1-744b-4b13-9b47-39cae6048bd3",
        name: "Autumn Harvest",
      },
      { assetId: "211c6fbe-c346-477e-bb0b-2cc25c5b0247", name: "Barley" },
      { assetId: "43b07b97-de38-4728-9542-d0f70ad7591c", name: "Cabernet" },
      { assetId: "eb5e1487-fa22-411e-b4ae-17a52a72a1b4", name: "Cypress" },
      { assetId: "74e058ff-7ded-41c0-b40e-8aad82c46c41", name: "Indigo" },
      { assetId: "379720d4-c86b-4c6e-8401-376bc892e3a1", name: "Granite" },
      { assetId: "25addead-3c5a-49ee-af6d-1ad3bd64968c", name: "Apline" },
      { assetId: "41d94014-4776-4f92-8498-a6cfbd30e37e", name: "Ruby Red" },
    ],
    //defaultValue: { assetId: "4de5b271-72cf-4744-900d-5ca3ea215d79",
  },
  {
    type: "String",
    name: "Handleset",
    id: "82f9cf61-9bc5-4a01-8330-5d7ce12ae7de",
    blacklist: [],
    //assetType: "material",
    values: [
      "Handle 1 Matte",
      "Handle 1 Steel",
      "Handle 2 Matte",
      "Handle 2 Steel",
    ],
    //defaultValue: { "Single Door with Sidelites" },
  },
  {
    type: "String",
    name: "Strap Hinges",
    id: "0cd31d4b-d4c4-4d40-a519-a5749e1c9e76",
    blacklist: [],
    //assetType: "material",
    values: [
      "None",
      "Matte",
    ],
    //defaultValue: { "Single Door with Sidelites" },
  },
];

export default attrs;
