import { Menu, Dropdown, Button, Avatar } from "antd";
import React, { useState, useEffect } from "react";
import attrs from "./attrs";
import "./style.css";

console.log(attrs);

function AttrForm() {
  const [customAttrs, setCustomAttrs] = useState(attrs);
  const [doorStyleSplice, setDoorStyleSplice] = useState();
  const [sideliteSplice, setSideliteSplice] = useState();
  useEffect(() => {
    // Update the document title using the browser API
    document.getElementById("sidelite-btn").style.display = "none";
    document.getElementsByClassName("surround-btn")[0].style.display = "none";

  }, []);

  const handleClick = (e, index, val) => {
    console.log("e");
    console.log(e);
    if (e.key == "56bba86d-0674-4ffd-a2d9-8711ef11f3f0") {
      document.getElementById("glass-btn").style.display = "none";
    }
    if (
      e.key == "4de4072d-c569-4433-bd6d-582f48d1ecb8" ||
      e.key == "df58c805-a512-4dcb-a9fb-e3fe9c7acd0f"
    ) {
      document.getElementById("glass-btn").style.display = "inline";
    }

    if (index == 0) {
      console.log("Door Configuration");
      if (!e.key.includes("Sidelite")) {
        console.log("SITELITE SELECteD");
        document.getElementById("sidelite-btn").style.display = "none";
      } else {
        document.getElementById("sidelite-btn").style.display = "inline";

      }
      window.configurator.setConfiguration({ "Door Configuration": e.key });
      console.log(val);
    } else if (index == 1) {
      console.log("Door Finish");
      window.configurator.setConfiguration({
        "Door Finish": { assetId: e.key },
      });
      console.log(val);
    } else if (index == 2) {
      console.log("Door Style");
      window.configurator.setConfiguration({
        "Door Style": { assetId: e.key },
      });
      console.log(val);
    } else if (index == 3) {
      console.log("Glass Style");
      window.configurator.setConfiguration({ "Glass Style": e.key });
      console.log(val);
    } else if (index == 4) {
      console.log("Frame Finish");
      window.configurator.setConfiguration({
        "Frame Finish": { assetId: e.key },
      });
      console.log(val);
    } else if (index == 5) {
      console.log("Sidelite Finish");
      window.configurator.setConfiguration({
        "Sidelite Finish": { assetId: e.key },
      });
      console.log(val);
    } else if (index == 6) {
      console.log("Surround");
      window.configurator.setConfiguration({ Surround: e.key });
      console.log(val);
      if (e.key.includes("None")) {
        document.getElementsByClassName("surround-btn")[0].style.display = "none";
        // window.configurator.setConfiguration({"Surround Target": {assetId: "c1238eff-63a3-41e2-bc46-63245021a0c1"}})
      } else {
        document.getElementsByClassName("surround-btn")[0].style.display = "inline";


      }
    } else if (index == 7) {
      console.log("Surround Finish");
      window.configurator.setConfiguration({
        "Surround Finish": { assetId: e.key },
      });
      console.log(val);
    }else if (index == 8) {
      console.log("Handleset");
      window.configurator.setConfiguration({
        "Handleset": e.key,
      });
      console.log(val);
    }else if (index == 9) {
      console.log("Strap Hinges");
      window.configurator.setConfiguration({
        "Strap Hinges": e.key,
      });
      console.log(val);
    }
  };
  const menu = function (data, index) {
    return (
      <Menu>
        {!data ? (
          <Menu.Item>nomenu</Menu.Item>
        ) : (
          data.map((e) => (
            <Menu.Item
              key={e.assetId || e}
              data-key={e.assetId}
              data-index={index}
              onClick={(e) => handleClick(e, index, e.assetId || e)}
            >
              {e.thumb ? <Avatar src={e.thumb} /> : console.log("no thub")}

              {e.name || e}
            </Menu.Item>
          ))
        )}
      </Menu>
    );
  };

  function FormComponent(e) {
    if (e.id == "bb795626-5773-44e7-969e-615aa26ebfe6") {
      return (
        <Button className="attr-btn" key={e.id} id="glass-btn">
          {e.name}
        </Button>
      );
    }
  }
  return (
    <div>
      {customAttrs.map((e, i) => (
        <Dropdown overlay={menu(e.values, i)}>
          {e.id == "bb795626-5773-44e7-969e-615aa26ebfe6" ? (
            <Button className="attr-btn" key={e.id} id="glass-btn">
              {e.name}
            </Button>
          ) : (
            <Button
              className="attr-btn"
              key={e.id}
              id={e.name.includes("Sidelite") ? "sidelite-btn" : "other"}
              className={e.name.includes("Surround Finish") ? "surround-btn" : "other"}

            >
              {e.name}
            </Button>
          )}
        </Dropdown>
      ))}
    </div>
  );
}

export default AttrForm;
