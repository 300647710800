import React, {useState} from "react";
import { Icon, Card, List, Layout, Button } from "antd";
import http from "http";



function GetSetConfig(){
const [config, setConfig] = useState()

    const saveConfig = () => {
      
        fetch(
          "https://preview.threekit.com/api/configurations?bearer_token=6c6161a8-5880-4568-933e-d70a50f905f1",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              orgId: "75db581b-d18f-4a02-96ac-c42ba5e6c3ef",
              productId: "d2836037-a2b8-46ce-be7e-7aeb246f5066",
              productVersion: `${Math.floor(Math.random() * 10) + 1}`,
              variant: window.configurator.getConfiguration(),
              files: "string",
            }),
          }
        )
          .then(async function (response) {
            let json = await response.json();
            console.log(json);
            setConfig({config: json});
            return json;
            //await response.text()
          })
          .catch(function (err) {
            // Error :(
            console.log(err);
          });
      };
    
        return (
          <div id="product-content">
            <Button onClick={() => saveConfig()}>Save Design</Button><br/>
            <textarea id="config" value={JSON.stringify(config)} style={{width: "50%"}} rows="3"></textarea>
          </div>
        );
}


export default GetSetConfig;
